import React from 'react';
import ReactDOM from 'react-dom';
import Portfolio from './Portfolio';
import './styles/base.scss';
// import SWManager from './SWManager';

ReactDOM.render(
  <>
    <Portfolio />
    {/* <SWManager /> */}
  </>,
  document.querySelector('#root')
);
